<template>
  <div class="content-wrapper view-lab-product-list-manage pt-3">
    <div class="content">
      <div class="card">
        <div class="card-body">
          <h4><i class="fas fa-list"></i> ลิสต์งานแลป</h4>
          <div class="row mx-1 my-3">
            <b-form-input
              type="search"
              class="col required"
              autofocus
              v-model="filter"
              placeholder="ค้นหารายการแลป"
            ></b-form-input>
            <b-button
              v-if="isCounterOrManager"
              class="col-5 col-sm-4 col-md-3 ml-3"
              variant="primary"
              @click="openLabProductModal()"
            >
              <i class="fas fa-plus"></i> เพิ่มรายการ
            </b-button>
          </div>
          <b-table
            id="labProductTable"
            ref="labProductTable"
            hover
            small
            sort-icon-left
            head-variant="light"
            :items="getLabProductList"
            :fields="fields"
            :filter="filter"
            responsive
          >
            <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>

            <template v-slot:cell(shareDetail)="row"
              ><span v-if="row.item.df"> </span>{{ row.item.df }}
              {{ row.item.dfType === 0 ? "%" : "บาท" }}</template
            >
            <template v-slot:cell(buttons)="row">
              <b-button-group class="show-when-hovered" size="xs">
                <b-button
                  variant="outline-info"
                  :pressed="row.item.favorite"
                  @click="favToggle(row.item)"
                >
                  <i class="far fa-star"></i>
                </b-button>
                <b-button
                  v-if="isCounterOrManager"
                  variant="outline-secondary"
                  @click="openLabProductModal(row.item)"
                >
                  <i class="fas fa-edit"></i>
                </b-button>
                <b-button
                  v-if="isCounterOrManager"
                  variant="outline-danger"
                  @click="handleDeleteItem(row.item)"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
      </div>
      <LabProductModal ref="LabProductModal"></LabProductModal>
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import LabProductModal from "@/components/modal/LabProductItem";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

export default {
  name: "LabProductManage",
  components: {
    LabProductModal,
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "index",
          label: "",
          // variant: "secondary",
          class: "text-center",
        },
        {
          key: "code",
          label: "รหัส",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "nameTh",
          label: "รายการแลป",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "nameEn",
          label: "รายการแลป(Eng)",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "labCompany",
          label: "บริษัทแแลป",
          sortable: true,
          class: "narrow-spacing",
          formatter: (value, key, item) => {
            return this.getLabCompanyBy(item.companyLabId, "id")?.nameTh || "-";
          },
        },
        {
          key: "shareDetail",
          label: "คำนวนหัก DF",
          class: "narrow-spacing text-right",
        },

        {
          key: "creationDt",
          label: "วันที่สร้าง",
          sortable: true,
          class: "narrow-spacing",
          formatter: "formatDate",
        },
        {
          key: "buttons",
          label: "",
          class: "text-center",
        },
      ],
      filter: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      isAccessMenu: "moduleUser/getIsAccessMenu",

      getLabProductList: "moduleLab/getLabProductList",
      getLabCompanyList: "moduleLab/getLabCompanyList",
      getLabCompanyBy: "moduleLab/getLabCompanyBy",
    }),

    isCounterOrManager() {
      return this.isAccessMenu(["COUNTER", "MANAGER", "HEADCOUNTER"]);
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchLabCompanyList: "moduleLab/fetchLabCompanyList",
      fetchLabProductList: "moduleLab/fetchLabProductList",
      deleteLabProduct: "moduleLab/deleteLabProduct",
      addLabProductFavorite: "moduleLab/addLabProductFavorite",
      deleteLabProductFavorite: "moduleLab/deleteLabProductFavorite",
    }),
    init() {
      // this.isLoading = true;
      // this.fetchLabCompanyList({
      //   clinicUrl: this.$route.params.clinicUrl,
      //   branchUrl: this.$route.params.branchUrl,
      // }).then((res) => {
      //   this.fetchLabProductList({
      //     clinicUrl: this.$route.params.clinicUrl,
      //     branchUrl: this.$route.params.branchUrl,
      //   }).finally(() => {
      //     this.isLoading = false;
      //   });
      // });
    },
    moment() {
      return moment();
    },
    openLabProductModal(txItem) {
      this.$refs.LabProductModal.show(txItem);
    },
    favToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleLab/addLabProductFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleLab/deleteLabProductFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleDeleteItem(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบผลิตภัณฑ์นี้ ?",
        "คุณต้องการลบผลิตภัณฑ์นี้"
      ).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.deleteLabProduct({
            event: eventData,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
            .then((res) => {
              if (res.status == "200") {
                this.isLoading = false;
                this.showDialogToast(
                  "success",
                  `ลบรายการ "${eventData.nameTh}" สำเร็จ`
                );
              }
            })
            .catch(() => {
              this.isLoading = false;
              this.showDialogToast(
                "warning",
                `ลบรายการ "${eventData.nameTh}" ไม่สำเร็จ`
              );
            })
            .finally(() => {
              this.refreshList();
              this.$bvModal.hide("ItemItemModal");
            });
        }
      });
    },
    refreshList() {
      let clinicUrl = this.$route.params.clinicUrl;
      let branchUrl = this.$route.params.branchUrl;

      this.fetchLabProductList({
        clinicUrl,
        branchUrl,
      });
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
  },
  mounted() {
    let clinicUrl = this.$route.params.clinicUrl;
    let branchUrl = this.$route.params.branchUrl;

    this.fetchLabCompanyList({
      clinicUrl,
      branchUrl,
    });
    this.fetchLabProductList({
      clinicUrl,
      branchUrl,
    });
  },
};
</script>

<style scoped>
</style>
