<template>
  <b-modal
    modal-class="component-modal-lab-product"
    id="LabProductItem"
    ref="LabProductItem"
    size="lg"
    title="รายการแลป"
    hide-footer
    no-close-on-backdrop
    @hidden="setDefaultValue"
  >
    <b-form @submit.prevent="saveLabProduct">
      <b-form-group label-cols="3" label="รหัส:" label-align="right">
        <b-form-input v-model="labProduct.code"></b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ชื่อรายการ(ไทย):" label-align="right">
        <b-form-input
          v-model="$v.labProduct.nameTh.$model"
          class="required"
          :class="{ 'is-invalid': $v.labProduct.nameTh.$error }"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ชื่อรายการ(Eng):" label-align="right">
        <b-form-input v-model="labProduct.nameEn"></b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="บริษัทแลป:" label-align="right">
        <b-row>
          <b-col cols="10">
            <b-form-select
              v-model="labProduct.companyLabId"
              :options="getLabCompanyList"
              value-field="id"
              text-field="nameTh"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >-- ไม่ระบุ --</b-form-select-option
                >
              </template></b-form-select
            ></b-col
          >
          <b-col cols="2">
            <b-button block @click="openLabCompanyModal">
              <i class="fas fa-edit"></i>
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        class="mt-3"
        label-cols="3"
        label="หัก DF:"
        label-align="right"
      >
        <b-row>
          <b-input-group class="col">
            <b-form-input
              v-model="$v.labProduct.df.$model"
              number
              class="required"
              :class="{ 'is-invalid': $v.labProduct.df.$error }"
            ></b-form-input>
            <template v-slot:append>
              <b-input-group-text>{{
                labProduct.dfType == 0 ? "%" : "บาท"
              }}</b-input-group-text>
            </template>
          </b-input-group>
          <b-form-radio-group
            class="align-self-center"
            v-model="labProduct.dfType"
          >
            <b-form-radio :value="0">Percent</b-form-radio>
            <b-form-radio :value="1">Fixed</b-form-radio>
          </b-form-radio-group>
        </b-row>
      </b-form-group>

      <hr />
      <b-row class="d-flex flex-row-reverse">
        <div>
          <b-button class="ml-2 mr-3" type="submit" variant="primary">{{
            labProduct.id ? "แก้ไขรายการ" : "สร้างรายการ"
          }}</b-button>
        </div>
        <div>
          <b-button
            variant="danger"
            v-if="labProduct.id"
            @click="handleDeleteItem(labProduct)"
            >ลบรายการ</b-button
          >
        </div>
      </b-row>
    </b-form>
    <LabCompanyModal ref="LabCompanyModal"></LabCompanyModal>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import LabCompanyModal from "@/components/modal/LabCompany";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
import "@/theme/modal/AptItem.scss";
import {
  required,
  requiredIf,
  numeric,
  minLength,
  between,
  minValue,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "labProductItem",
  components: {
    Dialog,
    Loading,
    LabCompanyModal,
  },
  data() {
    return {
      isLoading: false,
      labProduct: {
        id: null,
        code: "",
        nameTh: "",
        nameEn: "",
        companyLabId: null,
        dfType: 0, //คำนวนเป็น %
        df: 50, //50 %
        // favourite: false,
        // creationDt: null,
      },
    };
  },
  validations: {
    labProduct: {
      nameTh: { required },
      df: {
        required,
        // between: () => {
        //   if (this.labProduct.dfType === 0) {
        //     return between(0, 100);
        //   }
        // },
        // minValue: () => {
        //   if (this.labProduct.dfType === 1) {
        //     return minValue(0);
        //   }
        // },
      },
    },
  },
  computed: {
    ...mapGetters({
      getLabCompanyList: "moduleLab/getLabCompanyList",
      getUserList: "moduleUser/getUserList",
      getUserListBy: "moduleUser/getUserListBy",
    }),
  },
  watch: {},

  methods: {
    ...mapActions({
      fetchLabCompanyList: "moduleLab/fetchLabCompanyList",
      fetchLabProductList: "moduleLab/fetchLabProductList",
      findLabProductById: "moduleLab/findLabProductById",
      deleteLabProduct: "moduleLab/deleteLabProduct",
    }),
    init() {},
    show(item) {
      this.$bvModal.show("LabProductItem");

      if (item) {
        this.labProduct = { ...item };
        if (!this.getLabCompanyList.find((i) => i.id == item.companyLabId)) {
          this.labProduct.companyLabId = null;
        }
      }
    },
    saveLabProduct() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      const without = (object, keys) =>
        keys.reduce((o, k) => {
          const { [k]: _, ...p } = o;
          return p;
        }, object);

      let event = without(this.labProduct, [
        "favorite",
        "branchId",
        "creationUid",
        "creationDt",
        "modifyUid",
        "modifyDt",
        "del",
      ]);
      if (!this.labProduct?.id) {
        delete event.id;
        delete event.favourite;
        this.$store
          .dispatch("moduleLab/createLabProduct", {
            event,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == "200") {
              this.$parent.showDialogToast(
                "success",
                `สร้างรายการ "${this.labProduct.nameTh}" สำเร็จ`
              );
            }
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
            this.refreshList();
            this.$bvModal.hide("LabProductItem");
          });
      } else {
        this.$store
          .dispatch("moduleLab/updateLabProduct", {
            event,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            this.$parent.showDialogToast(
              "success",
              `แก้ไขรายการ "${this.labProduct.nameTh}" สำเร็จ`
            );
          })
          .finally(() => {
            this.refreshList();
            this.isLoading = false;
            this.$bvModal.hide("LabProductItem");
          });
      }
    },
    handleDeleteItem(eventData) {
      this.$refs.Dialog.showAlertConfirm("ลบรายการนี้ ?", "").then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.deleteLabProduct({
            event: eventData,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
            .then((res) => {
              if (res.status == "200") {
                this.setDefaultValue();
                this.$parent.showDialogToast(
                  "success",
                  `ลบรายการ "${eventData.nameTh}" สำเร็จ`
                );
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$parent.showDialogToast(
                "warning",
                `ลบรายการ "${eventData.nameTh}" ไม่สำเร็จ`
              );
            })
            .finally(() => {
              this.refreshList();
              this.$bvModal.hide("LabProductItem");
            });
        }
      });
    },

    setDefaultValue() {
      this.labProduct = Object.assign(
        {},
        {
          id: null,
          code: "",
          nameTh: "",
          nameEn: "",
          companyLabId: null,
          dfType: 0, //คำนวนเป็น %
          df: 50, //50 %
          favourite: false,
          // creationDt: null,
        }
      );
      this.$v.$reset();
    },
    openLabCompanyModal() {
      this.$refs.LabCompanyModal.show();
    },
    refreshList() {
      let clinicUrl = this.$route.params.clinicUrl;
      let branchUrl = this.$route.params.branchUrl;

      this.fetchLabProductList({
        clinicUrl,
        branchUrl,
      });
    },
  },
};
</script>

<style>
.required {
  background-color: #ccecff6c;
}
</style>